@import "assets/css/variables.scss";

$switch-size: 30px;
$switch-height: $switch-size / 2;

%full-radius {
  border-radius: 50px;
}

%transition {
  transition: all 0.3s;
}

.switch {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: $switch-size;
  height: $switch-height;
  border: 1px solid $color-grey;
  overflow: hidden;
  @extend %full-radius;
  @extend %transition;
  cursor: pointer;
  background-color: $color-primary;
  opacity: 1;

  &--dot {
    position: absolute;
    width: $switch-height;
    height: $switch-height;
    background-color: $color-white;
    @extend %full-radius;
    @extend %transition;
  }

  &[data-on="true"] {
    .switch--dot {
      transform: translateX($switch-height - 1px);
    }
  }

  &[data-on="false"]:not([data-disabled="true"]) {
    opacity: 0.2;

    .switch--dot {
      transform: translateX(1px);
    }
  }

  &[data-disabled="true"] {
    opacity: 0.4;
    cursor: not-allowed;
    background-color: $color-grey;
  }
}
